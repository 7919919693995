import { request as axios } from '@/util/request';
import { WareData, PaginationData } from '@/api/operation/customer';
const getUrl = require('baimahu/url-with-param');
const { VUE_APP_API: FCREST } = process.env;
import fileDownload from 'js-file-download';

const API = {
  uploadfile:'/boss/indent/expresses',
  get_order_list: '/boss/indent/order',
  warehouse: '/boss/warehouse/warehouse',
  export: `${FCREST}/boss/indent/order`, // 导出
  send_out: '/boss/indent/express', // 发货
  change: '/boss/indent/express',  //订单编号和公司的修改
  abexport: `${FCREST}/boss/knight/order/exception/export`, // 异常订单导出
}

// 请求参数数据接口
export interface QueryParams {
  id?: string;
  express_name?: string;
  express_address?: string;
  goods_id?: string;
  goods_title?: string;
  warehouse_id?: number | string;
  order_create_start_time?: number | string;
  order_craete_end_time?: number | string;
  pay_type?: number | string;
  order_type?: number;
  is_download?: number;
  user_id?: string;
  current: number;
}
// 订单数据接口
export interface OrderData {
  cancel_time: number;
  closed_time: number;
  confirmed_time: number;
  create_time: number;
  delivery_time: number;
  express_address: string;
  express_cost: number;
  express_mobile: string;
  express_name: string;
  express_number: string;
  goods: WareData[];
  goods_cost: number;
  id: number;
  is_sf: number;
  number: string;
  paid_time: number;
  pay_type: number;
  remark: string;
  status: number;
  status_name: string;
  update_time: number;
  user_id: number;
  level?: number;
  children?: any;
}
// 初始化订单列表数据
export function initOrderData(remote?: PaginationData<OrderData>): PaginationData<OrderData> {
  remote = remote || {} as PaginationData<OrderData>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}
// 获取订单列表
export async function getOrderList(query_params: QueryParams = { current: 1 }) {
  const url = getUrl(API.get_order_list, query_params);
  return axios.get(url);
}

// 仓库数据接口
export interface WarehouseData {
  address: string;
  city_id: number;
  create_time: number;
  delivery_time: number;
  district_id: number;
  freight: number;
  freight_type: number;
  head_address: string;
  id: number;
  is_delete: number;
  name: string;
  parent: AreaData[];
  province_id: number;
  status: number;
  update_time: number;
}
// 区域数据接口
interface AreaData {
  create_time: number;
  delivery_time: number;
  freight: number;
  freight_type: number;
  id: number;
  region_id: number;
  region_name: string;
  status: number;
  update_time: number;
  warehouse_id: number;
}
// 初始化仓库数据
export function initWarehouseData(remote?: WarehouseData[]): WarehouseData[] {
  remote = remote || [];
  return remote;
}
// 获取发货仓库列表
export async function getWarehouseList() {
  const url = getUrl(API.warehouse);
  return await axios.get(url);
}

export async function uploadfile(file: any){
  return await axios({
    url:API.uploadfile,
    method:'POST',
    data:file
  })
}
// 导出
export async function exportData(query_params?: any) {
  const url = getUrl(API.export, { ...query_params });
  return new  Promise((resolve,reject)=>{
    axios.get(`https:${url}`).then(res=>{
      resolve(res)
    }).catch(()=>{
      reject(url)  
    })
    }
  )
  
}
//异常订单导出
export async function abexportData(query_params?: any) {
  const url = getUrl(API.abexport, { ...query_params });
  return new  Promise((resolve,reject)=>{
    axios.get(`https:${url}`, { responseType: 'blob' }).then((res)=>{
      if (res) {
        fileDownload(res as any,  '异常订单.xlsx');
      }
      resolve(res)
    }).catch(()=>{
      reject(url)  
    })
    }
  )
  
}

interface SendData {
  order_id: number;
  company: string;
  number: number;
}
export async function sendOut(send_data: SendData) {
  return await axios.post(API.send_out, send_data);
}
interface SendCompanyAndNumber{
  company: string;
  number: number;
}
export async function changeMsg(id: number,data: SendCompanyAndNumber){
  return await axios.put(`${API.change}/${id}`,data);
}