import { request as axios } from '@/util/request';
import { PaginationData } from '@/api/operation/customer';
import fileDownload from 'js-file-download';

const getUrl = require('baimahu/url-with-param');
const { VUE_APP_API: FCREST } = process.env;
const API = {
  refund_list: '/boss/indent/refund',
  warehouse: '/boss/warehouse/warehouse',
  export: `${FCREST}/boss/indent/refund/export`, // 导出
}

// 退货申请单数据接口
export interface RefundData {
  buyer_nickname: string;
  check_time: number;
  count: number;
  create_time: number;
  disagree_reason: string;
  disagree_reason_image: string;
  express_address: string;
  express_mobile: string;
  express_name: string;
  goods_image_url: string;
  goods_price: number;
  goods_title: string;
  id: number;
  order_goods_id: number;
  order_id: number;
  product_id: number;
  reason_detail: string;
  reason_image_url: string;
  reason_type: number;
  reject_reason: string;
  status: number;
  user_id: number;
  warehouse_id: number;
  warehouse_name: string;
  level?: number;
  children?: any;
}
// 查询参数数据接口
export interface QueryParams {
  id?: number | string;
  order_id?: number | string;
  express_name?: string;
  express_mobile?: string;
  exporess_address?: string;
  goods_title?: string;
  warehouse_id?: number | string;
  create_start_time?: string | number;
  create_end_time?: string | number;
  reason_type?: number | string;
  status?: number;
  is_download?: number | string;
  current?: number;
}
// 初始化退货申请单数据
export function initRefundData(remote?: PaginationData<RefundData>): PaginationData<RefundData> {
  remote = remote || {} as PaginationData<RefundData>;
  return {
    current: remote.current || 0,
    detail: remote.detail || [],
    last: remote.last || 0,
    limit: remote.limit || 0,
    total: remote.total || 0,
  }
}
// 获取退货申请单
export async function getRefundList(query_params: QueryParams = {
  is_download: '0',
  current: 1,
}) {
  const url = getUrl(API.refund_list, query_params);
  return await axios.get(url);
}

// 仓库数据接口
export interface WarehouseData {
  address: string;
  city_id: number;
  create_time: number;
  delivery_time: number;
  district_id: number;
  freight: number;
  freight_type: number;
  head_address: string;
  id: number;
  is_delete: number;
  name: string;
  parent: AreaData[];
  province_id: number;
  status: number;
  update_time: number;
}
// 区域数据接口
interface AreaData {
  create_time: number;
  delivery_time: number;
  freight: number;
  freight_type: number;
  id: number;
  region_id: number;
  region_name: string;
  status: number;
  update_time: number;
  warehouse_id: number;
}
// 初始化仓库数据
export function initWarehouseData(remote?: WarehouseData[]): WarehouseData[] {
  remote = remote || [];
  return remote;
}
// 获取发货仓库列表
export async function getWarehouseList() {
  const url = getUrl(API.warehouse);
  return await axios.get(url);
}

//同意退款
export async function confirmRefund(id: any, query_params: any) {
  const url = getUrl(`${API.refund_list}/${id}/edit`, query_params)
  return await axios.get(url);
}
// 导出
export async function exportData(query_params?: any) {
  const url = getUrl(API.export, { ...query_params });
  return new Promise((resolve, reject) => {
    axios.get(`https:${url}`, { responseType: 'blob' }).then(res => {
      fileDownload(res as any,'售后.xlsx');

      resolve(res)
    }).catch(() => {
      reject(url)
    })
  }
  )
}