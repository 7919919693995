import { request } from '@/util/request';
import { message } from 'ant-design-vue';

import fileDownload from 'js-file-download';
const getUrl = require('baimahu/url-with-param');

// 请求参数数据接口
export interface QueryParams {
  id?: string;
  express_name?: string;
  express_address?: string;
  goods_id?: string;
  goods_title?: string;
  warehouse_id?: number | string;
  order_create_start_time?: number | string;
  order_craete_end_time?: number | string;
  pay_type?: number | string;
  order_type?: number;
  is_download?: number;
  user_id?: string;
  current: number;
}

export async function getOrderList(query_params: QueryParams = { current: 1 }) {
  const url = getUrl('/boss/indent/order', query_params);
  const res = await request.get(url);
  if (res.status == 200) {
    return res.data || [];
  } else {
    message.error(res?.message || '');
    return [];
  }
}
export async function getOrderDetail(id) {
  const url = `/boss/indent/order/${id}`
  const res = await request.get(url);
  if (res.status == 200&&res.data) {
    return res.data;
  } else {
    message.error(res?.message || '');
    return undefined;
  }
}
export async function getOrderLog(order_id,limit = 99999,current =1) {
  const url = `/boss/indent/order/log`
  const res = await request.get(url,{params:{order_id,limit,current}});
  if (res.status == 200&&res.data) {
    return res.data;
  } else {
    message.error(res?.message || '');
    return undefined;
  }
}
export async function setOrderLog(order_id,content) {
  const url = `/boss/indent/order/log`
  const res = await request.post(url,{order_id,content});
  if (res.status == 200&&res.data) {
    return true;
  } else {
    message.error(res?.message || '');
    return false;
  }
}
export async function exportOrderList(
  query_params: QueryParams = { current: 1 },
  fileName?: string
) {
  const url = getUrl('/boss/indent/order/export', { ...query_params, is_download: 1 });
  const res: any = await request.get(url, { responseType: 'blob' });
  if (res) {
    fileDownload(res, fileName || '订单详情.xlsx');
  }
}

export async function deliverGoods(order_id: number,list: {company: string; number: string}[] ) {
  const url = `/boss/indent/express`;
  const res = await request.post(url, {
    order_id,
    list
  });
  if (res.status == 200) {
    message.success('发货成功');
    return true;
  } else {
    message.error('发货失败');

    return false;
  }
}

export async function putDeliverInof(id: number,company,number) {
  const url = `/boss/indent/express/${id}`;
  const res = await request.put(url, {
    company,
    number
  });
  if (res.status == 200) {
    message.success('编辑成功');
    return true;
  } else {
    message.error('编辑失败');

    return false;
  }
}

export async function getOrderLogistics(order_id: number,express_id: number) {
  const url = `/boss/indent/order/logistics`;
  try {
    const res = await request.get<{
      [key: string]: any;
      list: {
        time: string;
        status: string;
      }[];
    }>(url, { params: { order_id ,express_id} });
    if (res.status === 200 && res?.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
  return {list:[]};
}
